import React from "react";
import styled from "styled-components";
import { PageElement } from "../../../graphql/page-common";
import { getElementImage, getElementText } from "../../../utilities/pimcore-data-transformer";
import WorkTextIntro from "../work-text-intro";
import { Headline2, Headline3, Paragraph } from "../../../components/typography";
import { PimcoreImage } from "../../../components/pimcore-image";
import QuoteElement from "../../../components/quote-element/quote-element";
import { useMatchMedia } from "../../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../../utilities/animation-helper";

interface WorkingSectionProps {
  headline: string;
  intro: Array<PageElement>;
  content: Array<PageElement>;
}

const WorkingSection: React.FC<WorkingSectionProps> = ({ headline, intro, content }) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(wrapper.current, [wrapper?.current?.children || null], matchMobile);

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <>
      <WorkTextIntro content={getElementText(intro[1])} />
      <SectionWrapper>
        <Grid ref={wrapper}>
          <MediaCell1>
            <Headline2 dangerouslySetInnerHTML={{ __html: headline || "" }} />
          </MediaCell1>
          <MediaCell2>
            <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[0]) || "" }} />
          </MediaCell2>
          <MediaCell3>
            <PimcoreImage image={getElementImage(content[1])} modifier="w-full rounded-lg" withAspectRatio />
          </MediaCell3>
          <MediaCell4>
            <ParagraphWrapper padding="tablet:pr-16">
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[2]) || "" }} />
            </ParagraphWrapper>
          </MediaCell4>
          <MediaCell5>
            <QuoteElement
              message="Without the right people there wouldn’t be any success, because successful hires lead to a successful business."
              name="Mariya"
              position="HR Generalist"
              modifier="left-4 bottom-4 tablet:-left-40 tablet:bottom-52"
            />
            <PimcoreImage image={getElementImage(content[3])} modifier="w-full rounded-lg" withAspectRatio />
          </MediaCell5>
          <MediaCell6>
            <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[4]) }} />
          </MediaCell6>
          <MediaCell7>
            <QuoteElement
              message="Diversity and inclusion stand at the core of our recruitment team, while we strongly focus on potential and growth of our colleagues and candidates."
              name="Yuliya"
              position="Recruiter"
              modifier="left-4 bottom-4 tablet:-left-20 tablet:-top-20"
            />
            <PimcoreImage image={getElementImage(content[5])} modifier="w-full rounded-lg" withAspectRatio />
          </MediaCell7>
          <MediaCell8>
            <ParagraphWrapper>
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[6]) }} />
            </ParagraphWrapper>
          </MediaCell8>
          <MediaCell9>
            <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[7]) }} />
          </MediaCell9>
          <MediaCell10>
            <PimcoreImage image={getElementImage(content[8])} modifier="w-full rounded-lg" withAspectRatio />
          </MediaCell10>
          <MediaCell11>
            <ParagraphWrapper>
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[9]) }} />
            </ParagraphWrapper>
          </MediaCell11>
          <MediaCell12>
            <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[10]) }} />
          </MediaCell12>
          <MediaCell13>
            <PimcoreImage image={getElementImage(content[11])} modifier="w-full rounded-lg" withAspectRatio />
          </MediaCell13>
          <MediaCell14>
            <ParagraphWrapper padding="tablet:pr-12">
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[12]) }} />
            </ParagraphWrapper>
          </MediaCell14>
        </Grid>
      </SectionWrapper>
    </>
  );
};

export default WorkingSection;


const SectionWrapper = styled.div.attrs({ className: "scrollspy" })``;


const Grid = styled.div.attrs({
  className: "tablet:grid tablet:grid-cols-12 gap-4 px-5",
})``;
const ParagraphWrapper = styled.div.attrs<{ padding?: string; }>(({ padding }) => ({
  className: `${padding ? padding : "tablet:pr-16"}`,
})) <{ padding?: string; }>``;

const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-3 tablet:row-start-1 tablet:row-span-1 pr-4 tablet:pr-0 mb-4 tablet:mb-0",
})``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-6 tablet:col-span-6 tablet:row-start-1 tablet:row-span-3 mb-8 tablet:mt-auto tablet:mb-0",
})``;
const MediaCell4 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-3 tablet:row-span-3 mb-8 tablet:mb-0",
})``;
const MediaCell5 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-4 tablet:row-span-1 mb-14 tablet:mb-36 relative",
})``;

const MediaCell6 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-5 tablet:row-span-1 tablet:mt-20",
})``;
const MediaCell7 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-5 tablet:row-start-5 tablet:row-span-3 mb-8 tablet:mb-36 relative",
})``;
const MediaCell8 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-6 tablet:row-span-1 mb-14 tablet:mb-0",
})``;

const MediaCell9 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-3 tablet:row-start-8 tablet:row-span-1",
})``;
const MediaCell10 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-5 tablet:row-start-8 tablet:row-span-3 mb-8 tablet:mb-0",
})``;
const MediaCell11 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-9 tablet:row-span-3 mb-14 tablet:mb-36",
})``;

const MediaCell12 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-12 tablet:row-span-1",
})``;
const MediaCell13 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-5 tablet:row-start-12 tablet:row-span-3 mb-8 tablet:mb-0 self-center",
})``;
const MediaCell14 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-13 tablet:row-span-2 mb-8",
})``;